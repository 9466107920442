// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'

const config = useJwt.jwtConfig

const initialUser = () => {
  const item = window.localStorage.getItem('user')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    user: initialUser()
  },
  reducers: {
    handleLogin: (state, action) => {
      state.user = action.payload
      state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      localStorage.setItem('user', JSON.stringify(action.payload))
      localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload[config.storageTokenKeyName]))
    },
    handleLogout: state => {
      state.user = {}
      state[config.storageTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('user')
      localStorage.removeItem(config.storageTokenKeyName)
    }
  }
})

export const { handleLogin, handleLogout } = authSlice.actions

export default authSlice.reducer
